import React from "react";
import { Image } from "@chakra-ui/react";
import { FaLinkedin, FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <section
        style={{
          margin: 0,
          width: "100%",
          padding: "5vh 12vw",
          backgroundColor: "#131313",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "76vw",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: "1 100%" }}>
            <Image
              src="whitekoch.png"
              alt="Trainer helping client"
              style={{
                width: "80px",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </div>
          <div style={{ flex: "2 auto", marginTop: "4vh" }}>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "1.25rem",
                fontWeight: "700",
                color: "#FFFFFF",
              }}
            >
              What we do
            </p>
            <a
              href="/kochclient"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                }}
              >
                KōCH App
              </p>
            </a>

            <a
              href="/"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                }}
              >
                KōCH Portal
              </p>
            </a>
          </div>
          <div style={{ flex: "2 auto", marginTop: "4vh" }}>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "1.25rem",
                fontWeight: "700",
                color: "#FFFFFF",
              }}
            >
              Who we are
            </p>

            <a
              href="/about"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                }}
              >
                About us
              </p>
            </a>

            <a
              href="https://www.linkedin.com/company/k%C5%8Dch/"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                }}
              >
                KōCH Team
              </p>
            </a>

            <a
              href="https://www.linkedin.com/company/k%C5%8Dch/"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                }}
              >
                Careers
              </p>
            </a>
          </div>
          <div style={{ flex: "2 auto", marginTop: "4vh" }}>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "1.25rem",
                fontWeight: "700",
                color: "#FFFFFF",
              }}
            >
              Use KōCH
            </p>
            <a
              href="https://apps.apple.com/ca/app/koch-app/id6462204358"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                  color: "#D9D9D9",
                }}
              >
                KōCH iOS App
              </p>
            </a>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontSize: "1.80rem",
                fontWeight: "400",
                color: "#D9D9D9",
              }}
            >
              KōCH Android App
            </p> */}
            <a
              href="https://coach.mykoch.app/login"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                }}
              >
                KōCH Web Portal
              </p>
            </a>
          </div>
          <div style={{ flex: "2 auto", marginTop: "4vh" }}>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "1.25rem",
                fontWeight: "700",
                color: "#FFFFFF",
              }}
            >
              Need help?
            </p>
            <a
              href="https://www.linkedin.com/company/k%C5%8Dch/"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                }}
              >
                Contact Us
              </p>
            </a>
            <a
              href="https://www.linkedin.com/company/k%C5%8Dch/"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                }}
              >
                FAQ
              </p>
            </a>
            <a
              href="https://www.linkedin.com/company/k%C5%8Dch/"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                }}
              >
                Blogs
              </p>
            </a>
            <a
              href="https://mykoch.app/privacy-policy"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "400",
                }}
              >
                Privacy Policy
              </p>
            </a>
          </div>
          <div style={{ flex: "2 auto", marginTop: "4vh" }}>
            <a
              href="https://coach.mykoch.app/signup"
              target="_blank"
              style={{ textDecoration: "none", color: "#D9D9D9" }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.80rem",
                  fontWeight: "700",
                  color: "#FFFFFF",
                }}
              >
                Sign Up for free
              </p>
            </a>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "3vh",
            marginBottom: "3vh",
          }}
        >
          {/* LinkedIn */}
          <div
            style={{
              borderRadius: "50%",
              backgroundColor: "white",
              padding: "5px",
              border: "1px solid rgba(255, 255, 255, 0.5)",
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <a
              href="https://www.linkedin.com/company/k%C5%8Dch/"
              target="_blank"
            >
              <FaLinkedin size={30} />
            </a>
          </div>
          {/* Instagram */}
          <div
            style={{
              borderRadius: "50%",
              backgroundColor: "white",
              padding: "5px",
              border: "1px solid rgba(255, 255, 255, 0.5)",
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <a href="https://www.instagram.com/mykoch.app/" target="_blank">
              <FaInstagram size={30} />
            </a>
          </div>
          {/* Facebook */}
          <div
            style={{
              borderRadius: "50%",
              backgroundColor: "white",
              padding: "5px",
              border: "1px solid rgba(255, 255, 255, 0.5)",
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <a href="https://www.instagram.com/mykoch.app/" target="_blank">
              <FaFacebook size={30} />
            </a>
          </div>
          {/* YouTube */}
          <div
            style={{
              borderRadius: "50%",
              padding: "5px",
              backgroundColor: "white",
              border: "1px solid rgba(255, 255, 255, 0.5)",
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <a href="https://www.youtube.com/@KochApp" target="_blank">
              <FaYoutube size={30} />
            </a>
          </div>
        </div>
      </section>
      <div
        style={{
          position: "absolute",
          width: "100%",
          bottom: 0,
          backgroundColor: "rgba(255, 255, 255, 1)", // Fully white
          display: "flex",
          justifyContent: "center",
          height: "2.5vh",
          paddingTop: "0.5vh",
        }}
      >
        <p
          style={{
            width: "fit-content",
            color: "black",
            fontWeight: "500px",
            fontFamily: "Inter",
          }}
        >
          COPYRIGHT©{currentYear} KōCH Platform Techologies. ALL RIGHTS
          RESERVED.
        </p>
      </div>
    </>
  );
}

export default Footer;
