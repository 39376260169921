import React from "react";
import Navbar from "./components/Navbar";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import HomeComponent from "./components/Home.component";
import AboutComponent from "./components/About.component";
import KochclientComponent from "./components/Kochclient.component";
import CoachFormComponent from "./components/CoachForm.component";
import ClientFormComponent from "./components/ClientForm.component";
import CoachTutorialComponent from "./components/CoachTutorial.component";
import ClientTutorialComponent from "./components/ClientTutorial.component";
import JoinBetaComponent from "./components/JoinBeta.component";

import Footer from "./components/Footer";
import ScrollToTopButton from "./components/ScrollToTopButton";
import PrivacyPolicyComponent from "./components/PrivacyPolicy.component";
import TermsofServiceComponent from "./components/TermsofService.component";
import MobileTermsofServiceComponent from "./components/MobileTermsofService.component";
import CookiesPolicyComponent from "./components/CookiesPolicy.component";
import CopyrightComponent from "./components/Copyright.component";
import WelcomeCoachComponent from "./components/WelcomeCoach";
import CoachGettingStartedComponent from "./components/CoachGettingStarted";
import CoachKycSetupComponent from "./components/CoachKycSetup";

const App = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/">
          <HomeComponent />
        </Route>

        <Route path="/about">
          <AboutComponent />
        </Route>

        <Route path="/kochclient">
          <KochclientComponent />
        </Route>

        <Route path="/coachform">
          <CoachFormComponent />
        </Route>

        <Route path="/clientform">
          <ClientFormComponent />
        </Route>

        <Route path="/coach-tutorial">
          <CoachTutorialComponent />
        </Route>

        <Route path="/client-tutorial">
          <ClientTutorialComponent />
        </Route>

        <Route exact path="https://coach.mykoch.app/login">
          <JoinBetaComponent />
        </Route>

        <Route exact path="https://coach.mykoch.app/signup">
          <JoinBetaComponent />
        </Route>

        <Route path="/privacy-policy">
          <PrivacyPolicyComponent />
        </Route>

        <Route path="/website-term-of-service">
          <TermsofServiceComponent />
        </Route>

        <Route path="/mobile-term-of-service">
          <MobileTermsofServiceComponent />
        </Route>

        <Route path="/cookie-policy">
          <CookiesPolicyComponent />
        </Route>

        <Route path="/copyright">
          <CopyrightComponent />
        </Route>

        <Route path="/welcome-coach">
          <WelcomeCoachComponent />
        </Route>

        <Route path="/coach-getting-started">
          <CoachGettingStartedComponent />
        </Route>

        <Route path="/coach-kyc-setup">
          <CoachKycSetupComponent />
        </Route>

      </Switch>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default App;
