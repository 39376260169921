import React from 'react'

function CoachKycSetupComponent() {
  return (
    <div style={{ marginTop: '10vh' }}>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe style={{ height: '100vh', width: '100vw' }} src="KYC.html"></iframe>
    </div>
  )
}

export default CoachKycSetupComponent;