import React from "react";
import { Image } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heroimage from "../assets/Heroimage.webp";
import Webportal from "../assets/Webportal.webp";
import Webportalnew from "../assets/Webportalnew.webp";
import Webportalmain from "../assets/Webportalmain.png";
import Simockup from "../assets/SiMockup.png";
import Fimockup from "../assets/FiMockup.webp";
import KōCHApp from "../assets/KoCHApp.webp";
import Appfour from "../assets/Appfour.webp";
import Anywhere from "../assets/Anywhere.webp";
import appstore from "../assets/appstore.jpg";
import one from "../assets/one.webp";
import two from "../assets/two.webp";
import three from "../assets/three.png";
import threepfive from "../assets/threepfive.webp";
import Aethoscolour from "../assets/Aethoscolour.webp";
import Trainerhelpingbeginner from "../assets/Trainerhelpingbeginner.webp";
import Trainerhelping from "../assets/Trainerhelping.webp";

function HomeComponent() {
  const testimonials = [
    {
      text: "I Recently moved and I was looking to find a tool to help me manage my personal training business. has given me structure and control to transition my business into a digital asset.",
      author: "Brendan Campbell",
      role: "Lead Trainer at Club 16",
      image: Trainerhelping,
    },
    {
      text: "I love the simplicity, it's so user friendly! The platforms functionality, allows me to manage my client and customize my pricing",
      author: "Coach Andrew Buduria",
      role: "Barry's Boot Camp Trainer",
      image: Aethoscolour,
    },
    {
      text: "I'm currently using multiple toolsto run my digital training business. KōCH is going to solve major problem for me. excited is an under statement",
      author: "Alana Patricia",
      role: "Online Fitness Trainer",
      image: Trainerhelpingbeginner,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 100,
    arrows: false,
    appendDots: (dots) => (
      <div
        style={{
          textAlign: "center",
          marginBottom: "-2vh",
        }}
      >
        <ul style={{ margin: "0px", padding: "0px", listStyle: "none" }}>
          {dots}
        </ul>
      </div>
    ),
    customPaging: () => (
      <button
        style={{
          width: "15px",
          height: "15px",
          margin: "0 px",
          borderRadius: "100%",
          cursor: "pointer", // Change cursor to pointer on hover
        }}
      />
    ),
  };

  return (
    <>
      <section
        className="hero-section"
        style={{
          padding: 0,
          margin: "12vh 12vw 6vh 12vw",
        }}
      >
        <h1
          style={{
            fontFamily: "Inter",
            fontSize: "4.75rem", // 60 / 16 = 3.75 (converted to rem)
            fontWeight: 600, // Medium font weight
            textTransform: "none",
            color: "#000000", // Font color from Figma
            margin: 0,
            padding: 0,
          }}
        >
          Helping you perform better.
        </h1>
        <p
          style={{
            fontFamily: "Inter",
            fontSize: "1.75rem", // 20 / 16 = 1.25 (converted to em)
            color: "#282828", // Font color from Figma
            fontWeight: 400, // Regular font weight
            padding: 0,
            margin: 0,
            marginTop: "1.5rem",
            textAlign: "start",
            maxWidth: 700,
            textTransform: 'none',
          }}
        >
          At long last, a cutting-edge personal training platform that empowers
          you to digitize your services and boost your earning is here.
        </p>
        <a
          href="https://coach.mykoch.app/login"
          target="_blank"
          rel="noreferrer"
        >
          <button
            style={{
              width: "168px", // Width = W
              height: "49px", // Height = H
              background: "#313131", // Background color from Figma
              color: "#ffffff", // Font color from Figma
              fontSize: "1rem", // Medium font size
              borderRadius: "24px", // Corner radius from Figma
              border: "none", // No border
              textAlign: "center", // Center align text
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3rem",
            }}
          >
            {" "}
            <span
              style={{
                width: "140px", // Width = W
                height: "13px", // Height = H
                fontSize: "1.5rem", // Regular font size (18px converted to rem)
                lineHeight: "79.3%", // Line height based on Figma
                fontWeight: "400", // Regular font weight
                textAlign: "center", // Center align text
                verticalAlign: "top", // Align top
                display: "inline-block",
              }}
            >
              Join coach now
            </span>
          </button>
        </a>
        <div
          className="image-container"
          style={{
            width: "76vw",
          }}
        >
          <Image
            src={Heroimage}
            alt="Hero Image"
            style={{
              borderRadius: "20px",
              width: "100%",
              height: "auto",
              objectFit: "cover",
              marginTop: "3rem",
            }}
          />
        </div>
      </section>

      <section
        className="hero-section"
        style={{
          padding: 0,
          margin: "12vh 12vw 6vh 12vw",
        }}
      >
        <p
          style={{
            fontFamily: "Inter",
            fontSize: "1.75em",
            fontWeight: 500,
            textTransform: "capitalize",
            color: "#A3A3A3",
            padding: 0,
          }}
        >
          INTRODUCING
        </p>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            width: "76vw",
            flexWrap: "wrap-reverse",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              fontFamily: "Inter",
              fontSize: "4.75rem", // 60 / 16 = 3.75 (converted to rem)
              fontWeight: 600, // Medium font weight
              textTransform: "none",
              color: "#000000", // Font color from Figma
              padding: 0,
            }}
          >
            KōCH Web Portal
          </h1>
          <p
            style={{
              fontFamily: "Inter",
              fontSize: "2.25rem", // 60 / 16 = 3.75 (converted to rem)
              fontWeight: 500, // Medium font weight
              textTransform: "none",
              color: "#395B88",
              padding: 0,
              paddingTop: 25,
            }}
          >
            Better by design.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "76vw",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: '2 35.01rem', marginRight: '0.8rem', marginBottom: '0.8rem' }}>
            <div
              style={{
                backgroundColor: "#F1F5F9",
                width: '100%',
                borderRadius: 26,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "2rem",
                  color: "#000000",
                  textTransform: "none",
                  textAlign: "left",
                  paddingTop: '2rem',
                  paddingLeft: '3.5rem',
                  paddingRight: '3.5rem',
                  paddingBottom: '1rem',
                }}
              >
                KōCH gives you the ability to scale your business and has automated systems to <strong> increase your reach and earning potential.</strong>
              </p>
              <Image
                src={Webportalmain}
                alt="Trainer helping client"
                style={{
                  marginBottom: '1rem',
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                marginTop: "0.8rem",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Image
                src={Webportal}
                alt="Second image"
                style={{
                  width: "43%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
              <Image
                src={Webportalnew}
                alt="Third image"
                style={{
                  width: "56%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
          <div style={{ flex: "1 31.31rem", marginBottom: '0.8rem' }}>
            <div
              style={{
                background: "linear-gradient(149deg, #DBEAFF 0%, #E2FDFF 100%)",
                width: "100%",
                height: "100%",
                borderRadius: 26,
                padding: "4rem",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <h1
                style={{
                  fontFamily: "Inter",
                  fontSize: "2.75rem", // 60 / 16 = 3.75 (converted to rem)
                  fontWeight: 400,
                  textTransform: "none",
                  color: "#000000", // Font color from Figma
                  padding: 0,
                }}
              >
                An all in one platform that<strong> allows you to compete.</strong>
              </h1>
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.5rem", // 60 / 16 = 3.75 (converted to rem)
                  fontWeight: 400,
                  color: "#000000",
                  textTransform: "none",
                  padding: 0,
                  textAlign: "left",
                }}
              >
                <br />
                <span>→</span>
                {'\t'}Effortlessly manage your appointments and streamline your
                calendar.
                <br />
                <br />
                <span>→</span>
                {'\t'}Craft personalized service lists for your clients, offering them
                a curated selection of options.
                <br />
                <br />
                <span>→</span>
                {'\t'}Design custom packages that perfectly align with your clients'
                unique needs and goals.
                <br />
                <br />
                <span>→</span>
                {'\t'}Monitor your clients' daily workout progress , gaining valuable
                insights into their performance.
              </p>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  width: "100%",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
              >
                <a
                  href="https://coach.mykoch.app/signup"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    style={{
                      flex: 1,
                      paddingLeft: 30,
                      paddingRight: 30,
                      borderRadius: 11,
                      height: "50px",
                      background: "#100F0F",
                      color: "#ffffff",
                      fontSize: "medium",
                      fontWeight: "bold",
                      marginTop: 10,
                    }}
                  >
                    Sign Up
                  </button>
                </a>
                <a
                  href="/coach-tutorial"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    style={{
                      flex: 2,
                      paddingLeft: 30,
                      paddingRight: 30,
                      borderRadius: 11,
                      height: "50px",
                      background: "transparent",
                      color: "000000",
                      border: "1px solid #000000",
                      fontSize: "medium",
                      fontWeight: "bold",
                      marginTop: 10,
                    }}
                  >
                    View all features
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="hero-section"
        style={{
          padding: 0,
          margin: "12vh 12vw 6vh 12vw",
        }}
      >
        <p
          style={{
            fontFamily: "Inter",
            fontSize: "1.75em",
            fontWeight: 500,
            textTransform: "capitalize",
            color: "#A3A3A3",
            padding: 0,
          }}
        >
          DISCOVER
        </p>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            width: "76vw",
            flexWrap: "wrap-reverse",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              fontFamily: "Inter",
              fontSize: "4.75rem", // 60 / 16 = 3.75 (converted to rem)
              fontWeight: 600, // Medium font weight
              textTransform: "none",
              color: "#000000", // Font color from Figma
              padding: 0,
            }}
          >
            KōCH App
          </h1>
          <p
            style={{
              fontFamily: "Inter",
              fontSize: "2.25rem", // 60 / 16 = 3.75 (converted to rem)
              fontWeight: 500, // Medium font weight
              textTransform: "none",
              color: "#9DC314",
              padding: 0,
              paddingTop: 25,
            }}
          >
            Anytime, anywhere.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "76vw",
            flexWrap: "wrap-reverse",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: "1 30.56rem" }}>
            <div
              style={{
                borderRadius: 26,
                background: "linear-gradient(158deg, #EEFEC2 0%, #D0FAFD 100%)",
                width: "100%",
                height: "100%",
                padding: "4rem",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.5rem", // 60 / 16 = 3.75 (converted to rem)
                  fontWeight: 400,
                  color: "#4F4F4F",
                  textTransform: "none",
                  padding: 0,
                  paddingBottom: 15,
                  textAlign: "left",
                }}
              >
                A SYSTEM DESIGNED FOR YOU!
              </p>
              <h1
                style={{
                  fontFamily: "Inter",
                  fontSize: "2.75rem", // 60 / 16 = 3.75 (converted to rem)
                  fontWeight: 400,
                  textTransform: "none",
                  color: "#000000", // Font color from Figma
                  padding: 0,
                }}
              >
                KōCH your client at<strong> any time, any location.</strong>
              </h1>
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "1.5rem", // 60 / 16 = 3.75 (converted to rem)
                  fontWeight: 400,
                  color: "#000000",
                  textTransform: "none",
                  padding: 0,
                  textAlign: "left",
                }}
              >
                <br />
                The pocket coach gives you the ability to hold your client
                accountable to achieve results.
                <br />
                <br />
                <span>→</span>
                {'\t'}Streamlined and hassle-free onboarding for your clients.
                <br />
                <br />
                <span>→</span>
                {'\t'}Communicate with your clients over calls and messaging and track
                their progress.
                <br />
                <br />
                <span>→</span>
                {'\t'}Efficiently and securely receive payments from your clients.
              </p>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  width: "100%",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
              >
                <a
                  href="https://apps.apple.com/ca/app/koch-app/id6462204358"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    style={{
                      flex: 1,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 11,
                      height: "50px",
                      background: "#100F0F",
                      color: "#ffffff",
                      fontSize: "medium",
                      fontWeight: "bold",
                      marginTop: 10,
                    }}
                  >
                    Download app
                  </button>
                </a>
                <a
                  href="/client-tutorial"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    style={{
                      flex: 1,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 11,
                      height: "50px",
                      color: "000000",
                      border: "1px solid #000000",
                      fontSize: "medium",
                      fontWeight: "bold",
                      marginTop: 10,
                    }}
                  >
                    View all features
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div style={{ flex: "2 35.76rem", marginLeft: '0.8rem' }}>
            <div
              style={{
                borderRadius: 26,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Image
                src={KōCHApp}
                alt="Trainer helping client"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                marginTop: 10,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  backgroundColor: "#F5F2EE",
                  borderRadius: 26,
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  marginRight: 10,
                  paddingTop: 30,
                }}
              >
                <Image
                  src={Fimockup}
                  alt="Second image"
                  style={{
                    width: "50%",
                    height: "auto",
                    objectFit: "scale-down",
                  }}
                />
              </div>
              <div
                style={{
                  backgroundColor: "#F5F2EE",
                  borderRadius: 24,
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  paddingBottom: 20,
                }}
              >
                <Image
                  src={Simockup}
                  alt="Third image"
                  style={{
                    width: "50%",
                    height: "auto",
                    objectFit: "scale-down",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Image
          src={Appfour}
          alt="Trainer helping client"
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            marginTop: "1vh",
          }}
        />
      </section>

      <section
        style={{
          padding: 0,
          margin: "12vh 0vw 6vh 0vw",
          backgroundImage: `url(${Anywhere})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "120vh",
        }}
      >
        <div
          style={{
            height: "50vh",
          }}
        >
          <h1
            style={{
              fontFamily: "Inter",
              fontSize: "4.75rem", // 60 / 16 = 3.75 (converted to rem)
              fontWeight: 600, // Medium font weight
              textTransform: "none",
              color: "#ffffff", // Font color from Figma
              margin: 0,
              padding: 0,
              marginLeft: "12vw",
              paddingTop: "12vh",
            }}
          >
            On the road? Traveling for work?
          </h1>
          <h1
            style={{
              fontFamily: "Inter",
              fontSize: "3.75rem", // 60 / 16 = 3.75 (converted to rem)
              fontWeight: 300, // Medium font weight
              textTransform: "none",
              color: "#ffffff", // Font color from Figma
              margin: 0,
              padding: 0,
              marginLeft: "12vw",
              marginTop: "2vh",
              width: "75%",
            }}
          >
            KōCH gives the ability to adapt and adjust to everyone’s schedule.
          </h1>
        </div>
        <div
          style={{
            height: "50vh",
          }}
        >
          <h1
            style={{
              fontFamily: "Inter",
              fontSize: "4.75rem", // 60 / 16 = 3.75 (converted to rem)
              fontWeight: 600, // Medium font weight
              textTransform: "none",
              color: "#ffffff", // Font color from Figma
              margin: 0,
              padding: 0,
              marginLeft: "12vw",
              paddingTop: "35vh",
            }}
          >
            COACH ANYWHERE
          </h1>
          <a
            href="https://apps.apple.com/ca/app/koch-app/id6462204358"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <button
              style={{
                fontFamily: "Inter",
                backgroundColor: "transparent",
                color: "#ffffff",
                border: "2px solid #ffffff",
                borderRadius: 11,
                padding: "10px 15px",
                fontSize: "2.0rem",
                cursor: "pointer",
                whiteSpace: "nowrap", // Ensure text stays on a single line
                marginLeft: "12vw",
                marginTop: "5vh",
              }}
            >
              Download KōCH App
            </button>
          </a>
        </div>
      </section>

      <section
        className="hero-section"
        style={{
          padding: 0,
          margin: "6vh 12vw 2vh 12vw",
        }}
      >
        <h1
          style={{
            fontFamily: "Inter",
            fontSize: "3.75em",
            fontWeight: 600,
            textTransform: "none",
            padding: 0,
            marginBottom: "2vh",
          }}
        >
          How it works
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: 1, marginBottom: "2vh" }}>
            <h1
              style={{
                fontSize: "2.25rem",
                fontWeight: 500,
                textTransform: "none",
                width: "90%",
                paddingBottom: "10px",
              }}
            >
              1. Comprehensive Client Management
            </h1>
            <p
              style={{
                fontSize: "1.50em",
                fontWeight: 400,
                textAlign: "left",
                margin: 0,
                width: "70%",
                padding: 0,
                marginTop: 5,
                paddingTop: 10,
                textTransform: 'none',
                borderTop: "2px solid #A3B82B",
              }}
            >
              Seamlessly organize and oversee your client base, ensuring
              effective communication and progress tracking.
            </p>
          </div>
          <div style={{ flex: 1, marginBottom: "2vh" }}>
            <h1
              style={{
                fontSize: "2.25rem",
                fontWeight: 500,
                textTransform: "none",
                width: "90%",
                paddingBottom: "10px",
              }}
            >
              2. Customizable Workout Builder
            </h1>
            <p
              style={{
                fontSize: "1.50em",
                fontWeight: 400,
                textAlign: "left",
                margin: 0,
                width: "70%",
                padding: 0,
                marginTop: 5,
                paddingTop: 10,
                textTransform: 'none',
                borderTop: "2px solid #A3B82B",
              }}
            >
              Tailor workout plans effortlessly to suit individual client needs,
              adjusting exercises, sets, and reps for optimal results.
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: 1, marginBottom: 20 }}>
            <h1
              style={{
                fontSize: "2.25rem",
                fontWeight: 500,
                textTransform: "none",
                width: "90%",
                paddingBottom: "10px",
              }}
            >
              3. Efficient Appointment Scheduling
            </h1>
            <p
              style={{
                fontSize: "1.50em",
                fontWeight: 400,
                textAlign: "left",
                margin: 0,
                width: "70%",
                padding: 0,
                marginTop: 5,
                paddingTop: 10,
                textTransform: 'none',
                borderTop: "2px solid #A3B82B",
              }}
            >
              Simplify scheduling with intuitive tools, allowing clients to book
              sessions and manage appointments hassle-free.
            </p>
          </div>
          <div style={{ flex: 1, marginBottom: 20 }}>
            <h1
              style={{
                fontSize: "2.25rem",
                fontWeight: 500,
                textTransform: "none",
                width: "90%",
                paddingBottom: "10px",
              }}
            >
              4. Customizable Payment Methods
            </h1>
            <p
              style={{
                fontSize: "1.50em",
                fontWeight: 400,
                textAlign: "left",
                margin: 0,
                width: "70%",
                padding: 0,
                marginTop: 5,
                paddingTop: 10,
                textTransform: 'none',
                borderTop: "2px solid #A3B82B",
              }}
            >
              Facilitate secure and hassle-free transactions, empowering you to
              manage payments and subscriptions seamlessly.
            </p>
          </div>
        </div>
      </section>

      <section
        className="hero-section"
        style={{
          display: "block",
          padding: 0,
          margin: 0,
          paddingTop: "1vh",
          paddingBottom: "6vh",
          paddingLeft: "12vw",
          paddingRight: "12vw",
          marginBottom: "6vh",
          backgroundColor: "#EDF8FB",
          width: "100%",
        }}
      >
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "76vw",
                  flexWrap: "wrap-reverse",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    flex: "4 auto",
                    paddingRight: "9vw",
                    textAlign: "left",
                    width: "55%",
                    marginTop: "3vh",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontSize: "1.75rem", // 60 / 16 = 3.75 (converted to rem)
                      fontWeight: 400,
                      color: "#4F4F4F",
                      textTransform: "none",
                      padding: 0,
                      textAlign: "left",
                    }}
                  >
                    ★ Tested and loved by 60+ coaches in North America
                  </p>
                  <h1
                    style={{
                      fontFamily: "Inter",
                      fontSize: "3.75rem",
                      fontWeight: 600,
                      textTransform: "none",
                      padding: 0,
                      paddingTop: "1vh",
                      paddingBottom: "2vh",
                    }}
                  >
                    Read the Testimonials
                  </h1>
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontSize: "1.75rem",
                      fontWeight: 400,
                      color: "#4F4F4F",
                      textTransform: "none",
                      padding: 0,
                      textAlign: "left",
                      paddingTop: "3vh",
                    }}
                  >
                    "{testimonial.text}"
                  </p>
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontSize: "2.00rem",
                      textTransform: "none",
                      padding: 0,
                      textAlign: "left",
                      paddingTop: "4vh",
                      fontWeight: "500",
                      color: "#153F57",
                    }}
                  >
                    {testimonial.author}
                  </p>
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontSize: "1.5rem",
                      textTransform: "none",
                      padding: 0,
                      textAlign: "left",
                      color: "#4F4F4F",
                      fontWeight: "500",
                    }}
                  >
                    {testimonial.role}
                  </p>
                </div>
                <div
                  style={{
                    flex: "2 auto",
                    marginTop: "3vh",
                  }}
                >
                  <Image
                    src={testimonial.image}
                    alt={`Trainer helping client ${index + 1}`}
                    style={{
                      // width: "350px",
                      width: "40vh",
                      height: "auto",
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>

      <section
        style={{
          padding: 0,
          margin: "12vh 0vw 6vh 0vw",
        }}>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              paddingLeft: "4vw",
              width: "42vw",
            }}
          >
            <Image
              src="kochlogo.png"
              alt="kochlogo"
              style={{
                marginLeft: "0px",
                height: "8vh",
                width: 'auto',
              }}
            />
            <p
              style={{
                fontSize: "2.2rem",
                fontWeight: "500",
                marginTop: "4vh",
                marginBottom: "2vh",
              }}
            >
              Download the KōCH client app on the app store.
            </p>
            <a
              href="https://apps.apple.com/ca/app/koch-app/id6462204358"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Image
                src={appstore}
                alt="Apple app store Image"
                style={{
                  width: "auto",
                  height: "5.2rem",
                }}
              />
            </a>
            <p
              style={{ fontSize: "1.6rem", fontWeight: "500", marginTop: "5vh" }}
            >
              Already tried this App?
              <br />
              <a
                href="/clientform"
                style={{ textDecoration: "underline" }}
                target="_blank" // Open the link in a new tab
              >
                Fill out the response form!
              </a>
            </p>
          </div>
          <div
            style={{
              width: "50vw",
              display: "flex",
              flexDirection: "row",
              overflow: "scroll",
            }}
          >
            <Image
              src={one}
              alt="phone image 1"
              h="50vh"
            />
            <Image
              src={two}
              alt="phone image 2"
              h="50vh"
            />
            <Image
              src={three}
              alt="phone image 3"
              h="50vh"
            />
            <Image
              src={threepfive}
              alt="phone image"
              h="50vh"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeComponent;
